<script>
import appConfig from "@/app.config";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import VueSelect from "vue-select";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penilaian PEG POG",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Datatable,
    Pagination,
    VueSelect,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      { width: "auto", label: "ID Jadwal", name: "id_jadwalasesi" },
      { width: "auto", label: "Nama", name: "id_jadwalasesi" },
      { width: "auto", label: "Jenis Asesmen", name: "id_jadwalasesi" },
      { width: "auto", label: "Keterangan", name: "id_jadwalasesi" },
      { width: "auto", label: "Lokasi", name: "ja_nip" },
      { width: "auto", label: "Tanggal Mulai", name: "ja_nama" },
      { width: "auto", label: "Tanggal Selesai", name: "ja_nama" },
      { width: "auto", label: "Nilai", name: "ja_nama" },
      { width: "auto", label: "Kategori", name: "ja_jabatan" },

      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Penilaian PEG POG",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Penilaian PEG POG",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      jadwalOptions: [],
      namaOptions: [],
      tahunOptions: [],
      years: [],

      selected_jenis_asesmen: [],
      // parsing data ke table data

      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_idjadwal: "",
        filter_nama: "",
        filter_tahun: "",
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;

    self.getDataTable();
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2024; year--) {
      this.years.push(year);
    }
    // self.getJenisAsesmen();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "rekap-qc/qc-asesor-pq") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      // self.tableData.filter_jenis_asesmen = self.selected_jenis_asesmen.nama_jenisasesmen;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
              self.currentTablePage = response_data_fix.list_data.current_page;
              self.jadwalOptions = response_data_fix.filter.idjadwal;
              self.namaOptions = response_data_fix.filter.nama;
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    exportExcel() {
      this.tableData.id_jadwal = this.idjadwal;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "rekap-qc/export/qc-asesor-pq",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = "Export Rekap QC Asesor PQ" + new Date().toISOString().slice(0, 10) + ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
    exportAll() {
      this.tableData.id_jadwal = this.idjadwal;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "rekap-qc/export/qc-asesor-ac",
        responseType: "blob",
        crossdomain: true,
        params: {
          draw: 0,
          length: 10,
          search: "",
          column: 0,
          dir: "asc",
          filter_idjadwal: "",
          filter_nama: "",
          filter_tahun: "",
        },
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Export Rekap QC Asesor AC" +
          new Date().toISOString().slice(0, 10) +
          ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="row">
      <div class="col-md-6">
        <h4>Rekap QC Asesor PQ</h4>
      </div>
      <div class="col-md-6">
        <div class="text-end">
          <!-- <a class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Tambah Jadwal Asesmen PEG POG</a> -->
        </div>
      </div>
      <div class="col-12 mb-2 mt-4 d-flex align-items-center justify-content-between">
        <div class="row col-8">
          <div class="col-md-3">
            <vue-select v-model="tableData.filter_idjadwal" :options="jadwalOptions" label="id_jadwal"
              :reduce="(option) => option.id_jadwal" placeholder="Filter Jadwal" />
          </div>
          <div class="col-md-3">
            <vue-select v-model="tableData.filter_nama" :options="namaOptions" :reduce="(option) => option.nama_asesor"
              label="nama_asesor" placeholder="Filter Nama" />
          </div>
          <div class="col-md-3">
            <vue-select v-model="tableData.filter_tahun" :options="years" placeholder="Filter Tahun" />
          </div>
          <div class="col-md-3">
            <button @click="getDataTable()" class="btn btn-primary btn-md"><i class="fa fa-search"></i> Filter</button>
          </div>
        </div>
        <div class="col-4 text-end">
          <button @click="exportExcel()" class="btn btn-success btn-sm me-2"><i class="fa fa-document"></i> Export
            Filter</button>
          <button @click="exportAll()" class="btn btn-success btn-sm">
            <i class="fa fa-document"></i> Export All
          </button>
        </div>
      </div>

      <hr />
      <div class="col-md-12 mb-2">
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                    v-model="tableData.length" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
              v-model="tableData.search" @input="getDataTable()" />
          </div>
        </div>
      </div>
      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
        <tbody>
          <tr v-if="loadingTable">
            <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
          </tr>
          <tr v-else-if="table_data == ''">
            <td class="text-center" colspan="6">Data Tidak Tersedia</td>
          </tr>
          <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
            <td class="text-center">
              <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
              <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
            </td>
            <td>
              {{ row_data.idjadwal }}
            </td>
            <td>
              {{ row_data.jr_nama }}
            </td>
            <td>
              {{ row_data.j_txt_dirkom }}
            </td>
            <td>
              {{ row_data.j_keterangan }}
            </td>
            <td>
              {{ row_data.j_txt_lokasi }}
            </td>
            <td>
              {{ row_data.j_tanggal_mulai }}
            </td>
            <td>
              {{ row_data.j_tanggal_sampai }}
            </td>
            <td>
              {{ row_data.total_jumlah }}
            </td>
            <td>
              {{ row_data.kategori }}
            </td>

            <td class="text-center">
              <router-link class="btn btn-info btn-sm me-2" :to="{
                name: 'detail-rekap-qc-asesor-pq',
                params: {
                  idjadwal: row_data.idjadwal,
                  iduser: row_data.jr_idasesor,
                },
              }"><i class="fa fa-eye"></i> Detail
              </router-link>
            </td>
          </tr>
        </tbody>
      </datatable>
    </div>
    <pagination :pagination="pagination" @to-page="toPage"></pagination>
  </div>
</template>
